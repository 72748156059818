import { DEPOT_HOST } from '@/constants';
import { atomWithDefault } from 'jotai/utils';

export const ItemDefinitionsAtom = atomWithDefault(async () => {
  // if (!DEPOT_HOST) return [];
  const response = await fetch(
    `${DEPOT_HOST}/assets/inventory/item_definitions.json`
  );
  const json = await response.json();

  if (response.ok) {
    return json;
  } else {
    return [];
  }
});
