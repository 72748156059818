import { Item, ItemDefinition } from '@/types';
import { isCartonItem } from './item/isCartonItem';

export const segmentRoomItems = (
  items: Array<Item>,
  itemDefinitions: ItemDefinition[]
) => {
  const roomItems: Item[] = [];
  const cartonItems: Item[] = [];

  items.forEach((item) => {
    if (isCartonItem(item, itemDefinitions)) {
      cartonItems.push(item);
    } else {
      roomItems.push(item);
    }
  });

  return { room: roomItems, carton: cartonItems };
};
