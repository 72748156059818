import { ChangeEvent, FormEvent, useState } from 'react';
import {
  Box,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItem,
  Modal,
  Paper,
  Stack,
  Typography,
  Chip,
  TextField,
  List,
  CardActions,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Item, ItemCategory, Image } from '@/types';
import {
  QuantityField,
  Button,
  ImageGallery,
  ImageDropzone,
  NumberField,
} from '@/components';
import {
  isCartonItem,
  isCrateItem,
  maybeAddImagesFromStrings,
  removeImages,
} from '@/utils';
import { truncateToTwoDecimalPlaces } from '@/utils/truncateToTwoDecimalPlaces';
import { DEFAULT_CRATE_CUBE, ITEM_CATEGORY } from '@/constants';
import { SegmentSelect } from '@/components/common/Segments/SegmentSelect';
import { useMoveTaskOrderOrders, useSurvey } from '@/hooks';
import { selectedSegmentIdAtom, ItemDefinitionsAtom } from '@/store';
import { useAtom } from 'jotai';
import { RoomSelect } from '@/components/common/Rooms/RoomSelect';
import { theme } from '@/services';

const CRATE_PADDING_IN_INCHES = 3;
const QUANTITY_INPUT_WIDTH = 80;

interface EditItemModalProps {
  open: boolean;
  item: Item;
  onClose: () => void;
  onChange: (item: Item) => void;
  onRemove: (item: Item) => void;
}

const inputLableStyles = {
  color: 'black',
  '& .MuiInputLabel-asterisk': {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    fontSize: '1.1em',
  },
};

export function EditItemModal({
  open,
  item,
  onClose,
  onChange,
  onRemove,
}: EditItemModalProps) {
  const { rooms } = useSurvey();
  const [tempItem, setTempItem] = useState(item);
  const [shouldConfirmRemove, setShouldConfirmRemove] = useState(false);
  const [itemDefinitions] = useAtom(ItemDefinitionsAtom);
  const needsPacking =
    isCrateItem(item, itemDefinitions) || isCartonItem(item, itemDefinitions);
  const showDimensionFields = isCrateItem(item, itemDefinitions);
  const shouldDeleteItem = tempItem.going === 0 && tempItem.notGoing === 0;
  const shouldUpdateItem = !shouldDeleteItem;

  const orders = useMoveTaskOrderOrders();
  const { segments } = useSurvey();
  const [selectedSegmentId] = useAtom(selectedSegmentIdAtom);

  const handleGoingChange = (value: number) => {
    setTempItem({
      ...tempItem,
      going: value,
      packing: needsPacking ? value : 0,
      unpacking: needsPacking ? value : 0,
    });
  };

  const handlePackingChange = (value: number) => {
    const newValue = value <= tempItem.going ? value : tempItem.packing;
    setTempItem({
      ...tempItem,
      packing: newValue,
      // we only want to link them if they are currently identical
      unpacking:
        tempItem.packing === tempItem.unpacking ? newValue : tempItem.unpacking,
    });
  };

  const handleUnpackingChange = (value: number) => {
    setTempItem({
      ...tempItem,
      unpacking: value <= tempItem.packing! ? value : tempItem.unpacking,
    });
  };

  const handleNotGoingChange = (value: number) => {
    setTempItem({
      ...tempItem,
      notGoing: value,
    });
  };

  const handleWeightChange = (weight: number | null) => {
    setTempItem({
      ...tempItem,
      weight,
    });
  };

  const handleCubeChange = (value: number | null) => {
    const cube = truncateToTwoDecimalPlaces(Number(value));
    setTempItem({
      ...tempItem,
      cube,
    });
  };

  const handleDimensionsChange = (field: string) => (value: number | null) => {
    const numValue = Number(value);
    const length = Number(tempItem.length);
    const width = Number(tempItem.width);
    const height = Number(tempItem.height);
    const padding = CRATE_PADDING_IN_INCHES;

    // (L + Padding) * (W + Padding) * (H + Padding) = CU_IN / 1728
    let cuIn = 0;
    if (field === 'length') {
      cuIn = (numValue + padding) * (width + padding) * (height + padding);
    } else if (field === 'width') {
      cuIn = (length + padding) * (numValue + padding) * (height + padding);
    } else if (field === 'height') {
      cuIn = (length + padding) * (width + padding) * (numValue + padding);
    }
    const cube = truncateToTwoDecimalPlaces(Number(cuIn / 1728));

    setTempItem({
      ...tempItem,
      // Default the cube 4 cu ft minimum
      cube: cube < DEFAULT_CRATE_CUBE ? DEFAULT_CRATE_CUBE : cube,
      [field]: value,
    });
  };

  const handleCategoryChange = (category: ItemCategory) => {
    const newCategory =
      category === tempItem.category ? ITEM_CATEGORY.HOUSEHOLD_GOODS : category;
    setTempItem({
      ...tempItem,
      category: newCategory as ItemCategory,
    });
  };

  const handleSegmentChange = (segmentId: string) => {
    setTempItem({
      ...tempItem,
      segmentId: segmentId,
    });
  };

  const handleRoomChange = (roomId: string) => {
    setTempItem({
      ...tempItem,
      roomId: roomId,
    });
  };

  const handleImageUpload = (imageStrings: Array<string>) => {
    setTempItem({
      ...tempItem,
      images: maybeAddImagesFromStrings(tempItem.images, imageStrings),
    });
  };

  const handleImageDelete = (image: Image) => {
    setTempItem({
      ...tempItem,
      images: removeImages(tempItem.images, image),
    });
  };

  const handleCommentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTempItem({
      ...tempItem,
      comments: event.target.value as string,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onChange(tempItem);
  };

  return (
    <Modal open={open} onClose={onClose} aria-label="Edit Item Modal">
      <Box
        sx={{
          position: 'absolute',
          top: {
            xs: 0,
            md: 'calc((100vh - 90vh - env(safe-area-inset-top))/2)',
          },
          left: 0,
          bottom: 0,
          right: 0,
          width: { xs: '100vw', md: '90vw', lg: '60vw' },
          maxHeight: {
            xs: 'calc(100vh - env(safe-area-inset-top))',
            md: 'calc(90vh - env(safe-area-inset-top))',
          },
          margin: '0 auto',
        }}
      >
        <Paper sx={{ height: 1 }}>
          <CardContent
            sx={{
              height: 1,
              p: { xs: 0, md: 2 },
              pb: { xs: 0, md: 3 },
              '&:last-child': {
                pb: { xs: 0, md: 3 },
              },
            }}
          >
            <form onSubmit={handleSubmit} style={{ height: '100%' }}>
              <List
                sx={{
                  py: { xs: 1, md: 0 },
                  position: { xs: 'sticky', lg: 'none' },
                  top: 0,
                }}
              >
                <ListItem divider sx={{ mb: { xs: 0, md: 4 } }}>
                  <Stack
                    direction="row"
                    width={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">{item.name}</Typography>

                    <IconButton aria-label="close-button" onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </ListItem>
              </List>

              <List
                sx={{
                  height: 'calc(100% - 150px)',
                  overflowY: 'auto',
                  overscrollBehavior: 'none',
                }}
              >
                <ListItem divider>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                  >
                    <InputLabel htmlFor="going-input">Going</InputLabel>
                    <div style={{ width: 150 }}>
                      <QuantityField
                        id="going-input"
                        name="going"
                        value={tempItem.going}
                        onChange={handleGoingChange}
                      />
                    </div>
                  </Stack>
                </ListItem>
                <ListItem sx={{ mb: 4 }} divider>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                  >
                    <InputLabel htmlFor="not-going-input">Not Going</InputLabel>
                    <div style={{ width: 150 }}>
                      <QuantityField
                        id="not-going-input"
                        name="notGoing"
                        value={tempItem.notGoing}
                        onChange={handleNotGoingChange}
                      />
                    </div>
                  </Stack>
                </ListItem>

                {needsPacking ? (
                  <>
                    <ListItem divider>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                      >
                        <InputLabel htmlFor="packing-input">Packing</InputLabel>
                        <div style={{ width: 150 }}>
                          <QuantityField
                            id="packing-input"
                            name="packing"
                            value={tempItem.packing}
                            onChange={handlePackingChange}
                          />
                        </div>
                      </Stack>
                    </ListItem>
                    <ListItem divider>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                      >
                        <InputLabel htmlFor="unpacking-input">
                          Unpacking
                        </InputLabel>
                        <div style={{ width: 150 }}>
                          <QuantityField
                            id="unpacking-input"
                            name="unpacking"
                            value={tempItem.unpacking}
                            onChange={handleUnpackingChange}
                          />
                        </div>
                      </Stack>
                    </ListItem>
                  </>
                ) : null}
                <ListItem sx={{ mb: 4 }} divider>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                  >
                    <FormGroup
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                tempItem.category ===
                                ITEM_CATEGORY.MEMBER_PRO_GEAR
                              }
                              onChange={() =>
                                handleCategoryChange(
                                  ITEM_CATEGORY.MEMBER_PRO_GEAR
                                )
                              }
                            />
                          }
                          label="Pro Gear"
                        />
                        <Chip label="PG" variant="teal" sx={{ mr: 4 }} />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                tempItem.category ===
                                ITEM_CATEGORY.SPOUSE_PRO_GEAR
                              }
                              onChange={() =>
                                handleCategoryChange(
                                  ITEM_CATEGORY.SPOUSE_PRO_GEAR
                                )
                              }
                            />
                          }
                          label="S. Pro Gear"
                        />
                        <Chip label="SG" variant="deepPurple" />
                      </div>
                    </FormGroup>
                  </Stack>
                </ListItem>

                <ListItem divider>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                  >
                    <InputLabel htmlFor="weight-input">Weight</InputLabel>
                    <NumberField
                      id="weight-input"
                      name="weight"
                      inputMode="numeric"
                      value={tempItem.weight}
                      size="small"
                      onChange={handleWeightChange}
                      sx={{ width: QUANTITY_INPUT_WIDTH }}
                    />
                  </Stack>
                </ListItem>
                <ListItem divider sx={{ mb: 4 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                  >
                    <InputLabel
                      htmlFor="cube-input"
                      required
                      sx={inputLableStyles}
                    >
                      Cu. Ft.
                    </InputLabel>
                    <NumberField
                      id="cube-input"
                      name="cube"
                      inputMode="decimal"
                      value={tempItem.cube}
                      size="small"
                      disabled={showDimensionFields}
                      onChange={handleCubeChange}
                      required
                      sx={{ width: QUANTITY_INPUT_WIDTH }}
                    />
                  </Stack>
                </ListItem>

                {showDimensionFields ? (
                  <>
                    <ListItem divider>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                      >
                        <InputLabel
                          htmlFor="length-input"
                          required
                          sx={inputLableStyles}
                        >
                          Length
                        </InputLabel>
                        <NumberField
                          id="length-input"
                          name="length"
                          inputMode="numeric"
                          value={tempItem.length}
                          size="small"
                          required
                          onChange={handleDimensionsChange('length')}
                          sx={{ width: QUANTITY_INPUT_WIDTH }}
                        />
                      </Stack>
                    </ListItem>
                    <ListItem divider>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                      >
                        <InputLabel
                          htmlFor="width-input"
                          required
                          sx={inputLableStyles}
                        >
                          Width
                        </InputLabel>
                        <NumberField
                          id="width-input"
                          name="width"
                          inputMode="numeric"
                          value={tempItem.width}
                          size="small"
                          required
                          onChange={handleDimensionsChange('width')}
                          sx={{ width: QUANTITY_INPUT_WIDTH }}
                        />
                      </Stack>
                    </ListItem>
                    <ListItem divider sx={{ mb: 4 }}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                      >
                        <InputLabel
                          htmlFor="height-input"
                          required
                          sx={inputLableStyles}
                        >
                          Height
                        </InputLabel>
                        <NumberField
                          id="height-input"
                          name="height"
                          inputMode="numeric"
                          value={tempItem.height}
                          size="small"
                          required
                          onChange={handleDimensionsChange('height')}
                          sx={{ width: QUANTITY_INPUT_WIDTH }}
                        />
                      </Stack>
                    </ListItem>
                  </>
                ) : null}

                <ListItem divider>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                  >
                    <InputLabel htmlFor="segment-select">Segment</InputLabel>
                    <SegmentSelect
                      segments={segments}
                      orders={orders}
                      selectedSegmentId={selectedSegmentId}
                      onChange={handleSegmentChange}
                    />
                  </Stack>
                </ListItem>

                <ListItem divider sx={{ mb: 4 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                  >
                    <InputLabel htmlFor="room-select">Room</InputLabel>
                    <RoomSelect
                      rooms={rooms}
                      selectedRoomId={item.roomId}
                      onChange={handleRoomChange}
                    />
                  </Stack>
                </ListItem>

                <ListItem>
                  <InputLabel htmlFor="notes-input">Notes</InputLabel>
                </ListItem>
                <ListItem divider>
                  <TextField
                    id="notes-input"
                    name="length"
                    value={tempItem.comments}
                    placeholder="Add your note here"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: 1 }}
                    rows={4}
                    onChange={handleCommentChange}
                    multiline
                  />
                </ListItem>

                <ListItem>
                  <Typography variant="subtitle2">Photos</Typography>
                </ListItem>
                <ListItem>
                  <ImageGallery
                    images={tempItem.images}
                    onDelete={handleImageDelete}
                  />
                </ListItem>
                <ListItem>
                  <ImageDropzone onUpload={handleImageUpload} />
                </ListItem>
              </List>

              <CardActions
                sx={{
                  position: { xs: 'sticky', md: 'none' },
                  bottom: 0,
                  backgroundColor: 'white',
                }}
              >
                {shouldUpdateItem ? (
                  <Button type="submit" fullWidth sx={{ mt: 2 }}>
                    Submit
                  </Button>
                ) : null}

                {shouldDeleteItem && !shouldConfirmRemove ? (
                  <Button
                    type="submit"
                    color="error"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={() => setShouldConfirmRemove(true)}
                  >
                    Remove
                  </Button>
                ) : null}

                {shouldDeleteItem && shouldConfirmRemove ? (
                  <Stack direction="row" gap={1} width={1}>
                    <Button
                      type="button"
                      variant="text"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={() => setShouldConfirmRemove(false)}
                    >
                      Cancel Remove
                    </Button>

                    <Button
                      type="button"
                      color="error"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={() => onRemove(tempItem)}
                    >
                      Confirm Remove
                    </Button>
                  </Stack>
                ) : null}
              </CardActions>
            </form>
          </CardContent>
        </Paper>
      </Box>
    </Modal>
  );
}
