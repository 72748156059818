import { ItemDefinition } from '@/types';

interface IsCartonItemProps {
  name: string;
  [key: string]: unknown;
}

export const isCartonItem = (
  item: IsCartonItemProps,
  itemDefinitions: ItemDefinition[]
) => {
  const definition = itemDefinitions.find(
    ({ name }: { name: string }) => name === item.name
  );
  return definition?.isCp || definition?.isPbo;
};
