import { ItemDefinition } from '@/types';
interface IsCrateItemProps {
  name: string;
  [key: string]: unknown;
}

export const isCrateItem = (
  item: IsCrateItemProps,
  itemDefinitions: ItemDefinition[]
) => {
  const definition = itemDefinitions.find(
    ({ name }: { name: string }) => name === item.name
  );
  return definition?.isCrate;
};
