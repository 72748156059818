import { useAtomValue, useAtom } from 'jotai';
import { getTotals, segmentRoomItems } from '@/utils';
import {
  itemsTotalsAtom,
  segmentItemsAtom,
  selectedSegmentIdAtom,
  ItemDefinitionsAtom,
} from '@/store';
import { PRO_GEAR_CATEGORIES } from '@/constants';
import { ProGearCategory } from '@/types/Item';
import { filterDeletedItems } from '@/utils/filterDeletedItems';

export const useRoomItems = (roomId: string) => {
  const itemTotals = useAtomValue(itemsTotalsAtom);
  const segmentId = useAtomValue(selectedSegmentIdAtom);
  const allSegmentItems = useAtomValue(segmentItemsAtom);
  const [itemDefinitions] = useAtom(ItemDefinitionsAtom);
  const segmentItems = filterDeletedItems(allSegmentItems);
  const roomItems = segmentItems.filter(
    (item) => item.roomId === roomId && item.segmentId === segmentId
  );
  const proGearItems = roomItems.filter((item) =>
    PRO_GEAR_CATEGORIES.includes(item.category as ProGearCategory)
  );

  const segmentedItems = segmentRoomItems(roomItems, itemDefinitions);
  const roomTotals = getTotals(roomItems);
  const proGearTotals = getTotals(proGearItems);

  return {
    itemTotals,
    roomTotals,
    proGearTotals,
    segmentedItems,
  };
};
